import React from 'react';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import { fromNowTime } from '../../apps/ahura/src/util/fromNowTime';
import { Link } from '@reach/router';
import PolicyViolationLabel from './PolicyViolationLabel';
import RelevanceTag from './RelevanceTag';
import Text from './Text';
import ComplianceTagStrip from './compliance-tag/ComplianceTagStrip';

const useStyles = makeStyles({
  card: {
    height: '100%',
  },
  cardContent: {
    '&:last-child': {
      paddingBottom: 16,
    },
    paddingBottom: 16,
  },
});

function PolicyViolationCardV2({
  relevance,
  title,
  id,
  status,
  date_resolved,
  exception_date_created,
  date_created,
  affected_asset_name,
  affected_asset_type_name,
  compliance_policy_references,
  link,
}) {
  const classes = useStyles();

  return (
    <Card classes={{ root: classes.card }}>
      <CardContent classes={{ root: classes.cardContent }}>
        <Link style={{ display: 'block' }} to={link}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Grid alignItems="center" container spacing={2}>
                <Grid item md={6} xs={12}>
                  <Grid alignItems="center" container spacing={2}>
                    <Grid item>
                      <RelevanceTag relevance={relevance} size="medium" />
                    </Grid>
                    <Grid item>
                      <Typography component="div" variant="body1">
                        {title}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Grid alignItems="center" container justifyContent={'flex-end'} spacing={2}>
                    <Grid item>
                      <Text component="div" variant="body">
                        {`ID: ${id.substr(0, 8)}`}
                      </Text>
                    </Grid>
                    <Grid item>
                      <PolicyViolationLabel status={status} />
                    </Grid>
                    <Box component={Grid} display={{ lg: 'none', md: 'none', xs: 'block' }} item xs={12}>
                      <Typography component="div" variant="body2">
                        {date_resolved
                          ? `Resolved ${fromNowTime(date_resolved)}`
                          : exception_date_created
                            ? `Closed ${fromNowTime(date_created)}`
                            : `Caught ${fromNowTime(date_created)}`}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid alignItems="center" container spacing={2}>
                <Grid item md={6} xs={12}>
                  <Grid alignItems="center" container spacing={2}>
                    <Grid item>
                      <Typography className={classes.rowLeft} component="div" variant="body2">
                        {affected_asset_type_name && affected_asset_name
                          ? `${affected_asset_type_name} at ${affected_asset_name}`
                          : 'Unknown Asset Affected'}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Grid alignItems="center" container justifyContent={'flex-end'} spacing={2}>
                    <Grid item>
                      <Typography component="div" variant="body2">
                        {date_resolved
                          ? `Resolved ${fromNowTime(date_resolved)}`
                          : exception_date_created
                            ? `Closed ${fromNowTime(date_created)}`
                            : `Caught ${fromNowTime(date_created)}`}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid alignItems="center" container spacing={2}>
                <Grid item xs={12}>
                  {compliance_policy_references && (
                    <ComplianceTagStrip complianceReferences={compliance_policy_references} granular />
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Link>
      </CardContent>
    </Card>
  );
}

export default React.memo(PolicyViolationCardV2);
